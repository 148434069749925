import { useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import { editSettings } from '../../../Api/Admin/settings';
import SaveSlideBar from '../../SaveSlideBar';

const ChangePasswordSettings = ({ fetchCurrentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [formData, setFormData] = useState({
    newPassword: '',
    reTypePassword: '',
  });

  const handleSelectedValue = (key, selectedValue) => {
    setFormData({ ...formData, [key]: selectedValue });
  };

  // API

  const handleSave = () => {
    if (
      formData.newPassword.length !== 0 &&
      formData.newPassword === formData.reTypePassword
    ) {
      editSettings(formData).then((res) => {
        fetchCurrentSettings();
        if (res.status === 200) {
          setIsSaveBar(false);
        }
      });
    }
  };

  const handleCancel = () => {
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
        }}
      >
        <Grid
          sx={{
            padding: { md: '15px 25px', xs: '15px 15px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <Typography
            sx={{ fontSize: '18px', fontWeight: 500, color: '#3A3A50' }}
          >
            Account Settings{' '}
            <span style={{ color: '#c1c1c1' }}>
              <CircleIcon sx={{ fontSize: '5px', marginX: '3px' }} /> Password
            </span>
          </Typography>
        </Grid>
        <Grid container sx={{ padding: { md: '15px 25px', xs: '15px 15px' } }}>
          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                New Password:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                value={formData.newPassword}
                onChange={(e) =>
                  handleSelectedValue('newPassword', e.target.value)
                }
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Re-type Password:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                value={formData.reTypePassword}
                onChange={(e) =>
                  handleSelectedValue('reTypePassword', e.target.value)
                }
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default ChangePasswordSettings;
