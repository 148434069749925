import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import AddPlan from './AddPlan';
import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';
import CustomTableFooter from '../../components/CustomTableFooter';
import Modal from '../../components/Modal';
import { deletePlanById, getPlanList } from '../../Api/Admin/plan';
import { CircularProgress } from '@mui/material';
import PriceFormatter from '../../utils/PriceFormatter';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import CustomButtonPages from '../../components/CustomButtonPages';

const Plans = () => {
  const [addPlansDrawer, setAddPlansDrawer] = useState(false);
  const [plans, setPlans] = useState();
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [activeTrialPlan, setActiveTrialPlan] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [search, setSearch] = useState(null);

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };
  const toggleAddPlansDrawer = () => {
    setAddPlansDrawer(!addPlansDrawer);
  };

  // API
  const fetchPlans = async () => {
    setTableLoading(true);
    getPlanList(100, 1).then((res) => {
      setPlans(res.data);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchPlans();
  }, []);

  // debounced search event
  const debouncedOnChangeHandler = useMemo(
    () =>
      debounce((e) => {
        setSearch(e.target.value);
      }, 300),
    []
  );

  //cleanup for debounce
  useEffect(() => {
    return () => {
      debouncedOnChangeHandler.cancel();
    };
  }, []);

  // fetch on search
  useEffect(() => {
    if (typeof search === 'string') {
      if (search.length > 3) {
        setTableLoading(true);
        getPlanList(100, 1, search).then((res) => {
          setPlans(res.data);
        });
      } else {
        fetchPlans();
      }
    }
  }, [search]);

  // update table rows on fetch
  useEffect(() => {
    if (plans) {
      setRows(plans);
      setTableLoading(false);
    }
  }, [plans]);

  const deletePlan = async (id) => {
    deletePlanById(id).then((res) => {
      fetchPlans();
    });
  };

  const columns = [
    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 140,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.value}
            {activeTrialPlan === params.id && (
              <StarIcon
                sx={{ color: '#ffbb44', marginLeft: '5px', fontSize: '20px' }}
              />
            )}
          </>
        );
      },
    },
    {
      field: 'mPrice',
      headerName: 'PRICE',
      minWidth: 170,
      editable: false,
      flex: 1,
      renderCell: (params) => PriceFormatter.format(params.value),
    },
    {
      field: 'orderNumber',
      headerName: 'ORDER NUMBER',
      minWidth: 150,
      editable: false,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 200,
      editable: false,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label="Set as Trial Plan"
          onClick={() => {
            setActiveTrialPlan(params.id);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setDrawerType('edit');
            setDrawerData(params.row);
            toggleAddPlansDrawer();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({ open: true, type: 'delete-modal', id: params.id });
          }}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      {openModal.type === 'delete-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
          handleDelete={deletePlan}
          id={openModal.id}
        />
      )}
      {addPlansDrawer && (
        <AddPlan
          toggleAddPlansDrawer={toggleAddPlansDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
          fetchPlans={fetchPlans}
        />
      )}
      {/*------------------ Teanants Heading---------------------- */}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{ marginTop: 5, marginBottom: 1, fontSize: 28, color: '#3A3A50' }}
        >
          Plans
        </Typography>
      </Grid>
      <Grid>
        <CustomButtonPages
          title="+ Add plan"
          isIcon={false}
          onClick={() => {
            setDrawerType('add');
            toggleAddPlansDrawer();
          }}
        />
      </Grid>
      {/* --------------------SEARCH------------------------------- */}
      <Grid
        item
        xs={12}
        sx={{
          marginTop: { xs: '15px' },
          paddingBottom: { xs: '30px' },
          borderBottom: { xs: '1px solid #e3eaf3' },
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            height: '60px',
            borderRadius: '2px',
            boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '60px',
            }}
          >
            <SearchIcon sx={{ color: '#b0c1c9', fontSize: '28px' }} />
          </div>
          <input
            style={{
              height: '100%',
              width: '100%',
              fontSize: '14px',
              boxSizing: 'border-box',
              border: 'none',
              outline: 'none',
              color: '#292d32',
            }}
            type="text"
            placeholder="Quick search"
            onChange={(e) => {
              debouncedOnChangeHandler(e);
            }}
          />
        </div>
      </Grid>
      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
                color: '#3A3A50',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell': {
                color: '#3A3A50',
              },
              minHeight: '60vh',
            }}
            rows={rows}
            columns={columns}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            loading={tableLoading}
            components={{
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Plans;
