import { useEffect, useLayoutEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SaveSlideBar from '../../SaveSlideBar';
import { getPlanList } from '../../../Api/Admin/plan';
import { editSettings } from '../../../Api/Admin/settings';

const GeneralSettings = ({ fetchCurrentSettings, currentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [allPlans, setAllPlans] = useState([]);
  const [selectedTrialPlan, setSelectedTrialPlan] = useState(null);
  const [trialPeriod, setTrialPeriod] = useState(null);
  const [selectedPlanForExpired, setSelectedPlanForExpired] = useState(null);
  const mediaQuery1238 = useMediaQuery('(min-width:1238px)');

  //  API

  const fetchPlans = async () => {
    const plans = await getPlanList(100, 1).then((res) => {
      setAllPlans([...res.data]);
      return [...res.data];
    });
    return plans;
  };

  // populating the data
  useLayoutEffect(() => {
    fetchPlans().then((plans) => {
      setTrialPeriod(+currentSettings?.trialPeriod);
      setSelectedTrialPlan(
        plans?.filter((plan) => {
          return plan.id == currentSettings?.trialPlanId;
        })[0]
      );
      setSelectedPlanForExpired(
        plans?.filter((plan) => {
          return plan.id == currentSettings?.expiredPlanId;
        })[0]
      );
    });
  }, []);

  // click handlers
  const handleSave = () => {
    editSettings({
      trialPeriod,
      expiredPlanId: selectedPlanForExpired.id,
      trialPlanId: selectedTrialPlan.id,
    }).then((res) => {
      fetchCurrentSettings();
      if (res.status === 200) {
        setIsSaveBar(false);
      }
    });
  };

  const handleCancel = () => {
    setTrialPeriod(currentSettings.trialPeriod);
    setSelectedTrialPlan(
      allPlans?.filter((plan) => {
        return plan.id == currentSettings.trialPlanId;
      })[0]
    );
    setSelectedPlanForExpired(
      allPlans?.filter((plan) => {
        return plan.id == currentSettings.expiredPlanId;
      })[0]
    );
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
        }}
      >
        <Grid
          sx={{
            padding: { md: '15px 25px', xs: '15px 15px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 500,
              color: '#3A3A50',
            }}
          >
            General Settings
          </Typography>
        </Grid>
        <Grid container sx={{ padding: { md: '15px 25px', xs: '15px 15px' } }}>
          <Grid
            xs={12}
            sm={mediaQuery1238 ? 4 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Trial plan:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                options={allPlans?.map((p) => {
                  return p;
                })}
                isOptionEqualToValue={(o, v) => o?.name == v?.name}
                value={selectedTrialPlan}
                getOptionLabel={(p) => {
                  return p.name;
                }}
                onChange={(e, data) => {
                  setSelectedTrialPlan(data);
                  setIsSaveBar(true);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select" size="small" />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            xs={12}
            sm={mediaQuery1238 ? 4 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Trial period (days):
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                size="small"
                type={'number'}
                required
                onChange={(e) => {
                  setTrialPeriod(e.target.value);
                  setIsSaveBar(true);
                }}
                value={trialPeriod}
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={mediaQuery1238 ? 4 : 12}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Plan for the expired tenants:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                options={allPlans?.map((p) => {
                  return p;
                })}
                isOptionEqualToValue={(o, v) => o?.name == v?.name}
                value={selectedPlanForExpired}
                getOptionLabel={(p) => {
                  return p.name;
                }}
                onChange={(e, data) => {
                  setSelectedPlanForExpired(data);
                  setIsSaveBar(true);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select" size="small" />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default GeneralSettings;
