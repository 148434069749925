import { UpnizeBackend } from "../Axios";

export const getNewsList = async (rows, page, searchQuery) => {
  let response = await UpnizeBackend.get(
    `/articles/list?Rows=${rows}&page=${page}${
      searchQuery ? `&keyword=${searchQuery}` : ""
    }`
  );
  console.log(response.data);
  return response;
};

export const getNewsById = async (id) => {
  let response = await UpnizeBackend.get(`/articles/byId/${id}`);
  console.log(response.data);
  return response;
};

export const addNews = async (body) => {
  let response = await UpnizeBackend.post(`/articles/add`, body);
  console.log(response.data);
  return response;
};

export const editNewsById = async (body, id) => {
  let response = await UpnizeBackend.put(`/articles/edit/${id}`, body);
  console.log(response.data);
  return response;
};

export const deleteNewsById = async (id) => {
  let response = await UpnizeBackend.delete(`/articles/delete/byId/${id}`);
  console.log(response.data);
  return response;
};

export const uploadImage = async (body) => {
  let response = await UpnizeBackend.post(`/articles/upload`, body);
  console.log(response.data);
  return response;
};
