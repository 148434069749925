import { UpnizeBackend } from "../Axios";

export const getTenantList = async (rows, page, searchQuery) => {
  let response = await UpnizeBackend.get(
    `/Tenants/list?Rows=${rows}&page=${page}${
      searchQuery ? `&keyword=${searchQuery}` : ""
    }`
  );
  console.log(response.data);
  return response;
};

export const getTenantById = async (id) => {
  let response = await UpnizeBackend.get(`/Tenants/byId/${id}`);
  console.log(response.data);
  return response;
};

export const addTenant = async (body) => {
  let response = await UpnizeBackend.post(`/tenants/add`, body);
  console.log(response.data);
  return response;
};

export const editTenantById = async (body, id) => {
  let response = await UpnizeBackend.put(`/tenants/edit/${id}`, body);
  console.log(response.data);
  return response;
};

export const deleteTenantById = async (id) => {
  let response = await UpnizeBackend.delete(`/Tenants/delete/${id}`);
  console.log(response.data);
  return response;
};

// send welcome email

export const sendWelcomeEmail = async (email) => {
  let response = await UpnizeBackend.get(`/Tenants/sendWelcome/${email}`);
  return response;
};
