import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';
import personDashboardIcon from '../../../assets/images/person-dashboard-icon.svg';
import timeDashboardIcon from '../../../assets/images/time-dashboard-icon.svg';
import walletDashboardIcon from '../../../assets/images/wallet-dashboard-icon.svg';
import refreshDashboardIcon from '../../../assets/images/refresh-dashboard-icon.svg';
import { getDashboard } from '../../../Api/Admin/dashboard';
import PriceFormatter from '../../../utils/PriceFormatter';
import CircularProgress from '@mui/material/CircularProgress';
import PersonIcon from '@mui/icons-material/Person';

const TotalPeople = ({ users = [] }) => {
  console.log(users);

  const [data, setData] = useState();
  const iconStyle = {
    border: '1px solid #1976d2',
    color: '#1976d2',
    p: '10px',
    width: '40px',
    height: '40px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  useEffect(() => {
    if (users.length > 0) {
      setData({
        totalUsers: users[0].all || 0,
        totalTenants: users[1].tenant || 0,
        totalStaff: users[2].staff || 0,
        totalCustomers: users[3].customer || 0,
      });
    }
  }, [users]);

  return (
    <Grid
      item
      style={{
        paddingBottom: '1rem',
      }}
      container
      sx={{ marginY: 1 }}
    >
      <Grid item xs={12} sm={6} lg={3} sx={{ padding: 1 }}>
        <Card>
          <CardContent sx={{ display: 'flex' }}>
            <Box
              sx={{
                color: '#1976d2',
                width: '80px',
                height: '80px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              <PersonIcon sx={iconStyle} />
            </Box>
            <Box sx={{ marginTop: 1, marginLeft: 1 }}>
              <Typography
                sx={{
                  fontSize: { xs: '6vw', sm: '3vw', md: '1.5vw' },
                  fontWeight: 600,
                  color: '#3A3A50',
                }}
                color="text.secondary"
              >
                {data ? data.totalUsers : <CircularProgress />}
              </Typography>
              <Typography
                sx={{ fontSize: 12, color: '#828F9A' }}
                color="text.secondary"
              >
                TOTAL USERS
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} sx={{ padding: 1 }}>
        <Card>
          <CardContent sx={{ display: 'flex' }}>
            <Box
              sx={{
                color: '#1976d2',
                width: '80px',
                height: '80px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              <PersonIcon sx={iconStyle} />
            </Box>{' '}
            <Box sx={{ marginTop: 1, marginLeft: 1 }}>
              <Typography
                sx={{
                  fontSize: { xs: '6vw', sm: '3vw', md: '1.5vw' },
                  fontWeight: 600,
                  color: '#3A3A50',
                }}
                color="text.secondary"
              >
                {data ? data.totalTenants : <CircularProgress />}
              </Typography>
              <Typography
                sx={{ fontSize: 12, color: '#828F9A' }}
                color="text.secondary"
              >
                TOTAL TENANTS
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} sx={{ padding: 1 }}>
        <Card>
          <CardContent sx={{ display: 'flex' }}>
            <Box
              sx={{
                color: '#1976d2',
                width: '80px',
                height: '80px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              <PersonIcon sx={iconStyle} />
            </Box>{' '}
            <Box sx={{ marginTop: 1, marginLeft: 1 }}>
              <Typography
                sx={{
                  fontSize: { xs: '6vw', sm: '3vw', md: '1.5vw' },
                  fontWeight: 600,
                  color: '#3A3A50',
                }}
                color="text.secondary"
              >
                {data ? data.totalStaff : <CircularProgress />}
              </Typography>
              <Typography
                sx={{ fontSize: 12, color: '#828F9A' }}
                color="text.secondary"
              >
                TOTAL STAFF
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} sx={{ padding: 1 }}>
        <Card>
          <CardContent sx={{ display: 'flex' }}>
            <Box
              sx={{
                color: '#1976d2',
                width: '80px',
                height: '80px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              <PersonIcon sx={iconStyle} />
            </Box>{' '}
            <Box sx={{ marginTop: 1, marginLeft: 1 }}>
              <Typography
                sx={{
                  fontSize: { xs: '6vw', sm: '3vw', md: '1.5vw' },
                  fontWeight: 600,
                  color: '#3A3A50',
                }}
                color="text.secondary"
              >
                {data ? data.totalCustomers : <CircularProgress />}
              </Typography>
              <Typography
                sx={{ fontSize: 12, color: '#828F9A' }}
                color="text.secondary"
              >
                TOTAL CUSTOMERS
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TotalPeople;
