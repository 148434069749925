import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import AddNews from './AddNews';
import CustomTableFooter from '../../components/CustomTableFooter';
import Modal from '../../components/Modal';
import StatusChip from '../../components/StatusChip';
import {
  deleteNewsById,
  editNewsById,
  getNewsList,
} from '../../Api/Admin/news';
import { CircularProgress } from '@mui/material';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import CustomButtonPages from '../../components/CustomButtonPages';

const row = [
  {
    id: 1,
    type: 'news',
    title: 'Hello world',
    created: '2022-08-22',
    status: 'Published',
  },
  {
    id: 2,
    type: 'blog',
    title: 'Hello blog world',
    created: '2022-08-24',
    status: 'Not Published',
  },
  {
    id: 3,
    type: 'help',
    title: 'Hello world',
    created: '2022-08-23',
    status: 'Published',
  },
];

const News = () => {
  const [news, setNews] = useState();
  const [addNewsDrawer, setAddNewsDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [search, setSearch] = useState(null);

  const toggleAddNewsDrawer = () => {
    setAddNewsDrawer(!addNewsDrawer);
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  // API
  const fetchNews = async () => {
    setTableLoading(true);
    getNewsList(100, 1).then((res) => {
      setNews(res.data);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchNews();
  }, []);

  // debounced search event
  const debouncedOnChangeHandler = useMemo(
    () =>
      debounce((e) => {
        setSearch(e.target.value);
      }, 300),
    []
  );

  //cleanup for debounce
  useEffect(() => {
    return () => {
      debouncedOnChangeHandler.cancel();
    };
  }, []);

  // fetch on search
  useEffect(() => {
    if (typeof search === 'string') {
      if (search.length > 3) {
        setTableLoading(true);
        getNewsList(100, 1, search).then((res) => {
          setNews(res.data);
        });
      } else {
        fetchNews();
      }
    }
  }, [search]);

  // update table rows on fetch
  useEffect(() => {
    if (news) {
      setRows(news);
      setTableLoading(false);
    }
  }, [news]);

  const deleteNews = async (id) => {
    deleteNewsById(id).then((res) => {
      fetchNews();
    });
  };

  const handleTogglePublish = (data) => {
    console.log(data.status == 1 ? 0 : 1);
    editNewsById(
      { ...data, status: data.status == 1 ? '0' : '1' },
      data.id
    ).then((res) => {
      fetchNews();
    });
  };

  // table columns
  const columns = [
    {
      field: 'type',
      headerName: 'TYPE',
      editable: false,
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'TITLE',
      editable: false,
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'CREATED',
      type: 'date',
      editable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      type: 'singleSelect',
      valueOptions: ['Published', 'Not Published'],
      editable: false,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.value == '1' ? (
            <StatusChip
              text={'Published'}
              color={'#8467E9'}
              bgColor={'#EAE5FB'}
              rounded={true}
            />
          ) : (
            <StatusChip
              text={'Not Published'}
              color={'#DF3E70'}
              bgColor={'#F9E0E7'}
              rounded={true}
            />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 150,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label="Publish on website"
          onClick={() => {
            handleTogglePublish(params.row);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Send test email"
          onClick={() => setOpenModal({ open: true, type: 'email' })}
          showInMenu
        />,
        <GridActionsCellItem
          label="Send as newsletter"
          onClick={() => setOpenModal({ open: true, type: 'newsletter' })}
          showInMenu
        />,
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setDrawerType('edit');
            setDrawerData(params.row);
            toggleAddNewsDrawer();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({ open: true, type: 'delete-modal', id: params.id });
          }}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      {addNewsDrawer && (
        <AddNews
          toggleAddNewsDrawer={toggleAddNewsDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
          fetchNews={fetchNews}
        />
      )}
      {openModal.type === 'newsletter' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'SEND_NEWS_LATER'}
          open={openModal.open}
        />
      )}
      {openModal.type === 'email' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'SEND_TEST_EMAIL'}
          open={openModal.open}
        />
      )}
      {openModal.type === 'delete-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
          handleDelete={deleteNews}
          id={openModal.id}
        />
      )}

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{ marginTop: 5, marginBottom: 1, fontSize: 28, color: '#3A3A50' }}
        >
          News / blog
        </Typography>
      </Grid>
      <Grid>
        <CustomButtonPages
          title="+ Add subject"
          isIcon={false}
          onClick={() => {
            setDrawerType('add');
            toggleAddNewsDrawer();
          }}
        />
      </Grid>
      {/* --------------------SEARCH------------------------------- */}
      <Grid
        item
        xs={12}
        sx={{
          marginTop: { xs: '15px' },
          paddingBottom: { xs: '30px' },
          borderBottom: { xs: '1px solid #e3eaf3' },
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            height: '60px',
            borderRadius: '2px',
            boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '60px',
            }}
          >
            <SearchIcon sx={{ color: '#b0c1c9', fontSize: '28px' }} />
          </div>
          <input
            style={{
              height: '100%',
              width: '100%',
              fontSize: '14px',
              boxSizing: 'border-box',
              border: 'none',
              outline: 'none',
              color: '#292d32',
            }}
            type="text"
            placeholder="Quick search"
            onChange={(e) => {
              debouncedOnChangeHandler(e);
            }}
          />
        </div>
      </Grid>
      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
                color: '#3A3A50',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell': {
                color: '#3A3A50',
              },
              minHeight: '60vh',
            }}
            rows={rows}
            columns={columns}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            // pagination={false}
            // hideFooterPagination
            loading={tableLoading}
            hideFooterSelectedRowCount
            selectionModel={selectionModel}
            components={{
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default News;
