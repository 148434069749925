import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useEffect, useReducer, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddPayment from './AddPayment';
import { CircularProgress, Menu, MenuList } from '@mui/material';
import CustomTableFooter from '../../components/CustomTableFooter';
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '../../components/Modal';
import StatusChip from '../../components/StatusChip';
import { deletePaymentById, getPaymentList } from '../../Api/Admin/payment';
import PriceFormatter from '../../utils/PriceFormatter';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import CustomButtonPages from '../../components/CustomButtonPages';

const FilterItem = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
    queryLabel: 'keyword',
  },

  {
    id: 2,
    title: 'Status',
    type: 'select',
    options: ['Paid', 'Pending'],
    isOpen: false,
    search: '',
    queryLabel: 'status',
  },
  {
    id: 3,
    title: 'Payment Method',
    type: 'select',
    options: ['Paypal', 'Credit Card', 'Offline'],
    isOpen: false,
    search: '',
    queryLabel: 'method',
  },
  {
    id: 4,
    title: 'Payment Cycle',
    type: 'select',
    options: ['Monthly', 'Annually'],
    isOpen: false,
    search: '',
    queryLabel: 'cycle',
  },
];

const row = [
  {
    id: '1',
    date: '2022-03-01 10:59',
    tenantId: 'Test Tenantss',
    planId: '12',
    amount: '45',
    paymentMethod: 'Credit Card',
    paymentCycle: 'Monthly',
    status: 'Paid',
  },
];

const appointmentReducer = (state, { type, payload }) => {
  switch (type) {
    case 'SELECT':
      const newArray = [...state];
      const findAns = newArray.find((item) => item.id === payload.id);
      if (findAns) {
        findAns.ans = payload.ans;
        return newArray;
      } else {
        const ans = [
          ...state,
          { id: payload.id, ans: payload.ans, queryLabel: payload.queryLabel },
        ];

        return ans;
      }
    case 'CLEAR':
      const ansData = state.filter((ans) => ans.id !== payload);
      return [...ansData];

    default:
      return state;
  }
};
const initalState = [];

const Payments = () => {
  const [addPaymentDrawer, setAddPaymentDrawer] = useState(false);
  const [payments, setPayments] = useState();
  const [drawerType, setDrawerType] = useState('add');
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterMenuList, setFilterMenuList] = useState([]);
  const [menuFilterInput, setMenuFilterInput] = useState('');
  const [filterMenuValue, setFilterMenuValue] = useState({
    status: '',
    paymentMethod: '',
    paymentCycle: '',
  });
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [filterOption, setFilterOption] = useState(FilterItem);
  const [state, dispatch] = useReducer(appointmentReducer, initalState);
  const [drawerData, setDrawerData] = useState(null);
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const toggleAddPaymentDrawer = () => {
    setAddPaymentDrawer(!addPaymentDrawer);
  };

  const fetchPayments = async () => {
    setTableLoading(true);
    getPaymentList(100, 1).then((res) => {
      setPayments(res.data);
    });
  };

  const deletePayment = async (id) => {
    deletePaymentById(id).then((res) => {
      fetchPayments();
    });
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const openMenu = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuFilterInput('');
  };

  const handleMenuFilterInput = (e) => {
    const value = e.target.value;

    if (value.length === 0) {
      switch (anchorEl.getAttribute('name')) {
        case 'status':
          setFilterMenuList(['Paid', 'Pending']);
          break;
        case 'payment-method':
          setFilterMenuList(['Paypal', 'Credit Card', 'Offline']);
          break;
        case 'payment-cycle':
          setFilterMenuList(['Monthly', 'Annually']);
          break;
        default:
          return;
      }
    } else {
      setFilterMenuList((prevState) =>
        prevState.filter((prevValue) => prevValue.startsWith(value))
      );
    }

    setMenuFilterInput(e.target.value);
  };

  const handleCheckedMenuFilter = (value) => {
    const menuName = anchorEl.getAttribute('name');

    setFilterMenuValue({
      ...filterMenuValue,
      [menuName]: value,
    });

    handleMenuClose();
  };

  const handelSelect = (optionObj, option) => {
    const categoryId = optionObj.id;
    dispatch({
      type: 'SELECT',
      payload: {
        id: categoryId,
        ans: option,
        queryLabel: optionObj.queryLabel,
      },
    });
    const newArray = [...filterOption];
    const optionObject = newArray.find((array) => array.id === categoryId);
    optionObject.isOpen = false;
    setFilterOption(newArray);
  };

  const findAns = (id) => {
    const find = state.find((item) => item.id === id);
    return find;
  };

  const handelClear = (calcelId) => {
    dispatch({ type: 'CLEAR', payload: calcelId });
  };

  const handelOpen = (id) => {
    const newArr = [...filterOption];
    const newObj = newArr.find((item) => item.id === id);
    newObj.isOpen = !newObj.isOpen;
    newArr.forEach((item) => {
      if (item.id !== id) {
        item.isOpen = false;
      }
    });
    setFilterOption(newArr);
  };

  const handelOnFilterOption = (id, value) => {
    const newArray = [...filterOption];
    const newObj = newArray.find((item) => item.id === id);
    newObj.search = value;
    setFilterOption(newArray);
  };

  // API

  // filtering Option
  const getQueries = async () => {
    let queryText = '';
    state.map((query, i) => {
      if (query.queryLabel === 'keyword') {
        if (query.ans.length > 3) {
          queryText = queryText + `&${query.queryLabel}=${query.ans}`;
        }
      } else {
        queryText = queryText + `&${query.queryLabel}=${query.ans}`;
      }
    });

    return queryText;
  };

  // debounced search event
  const debouncedOnChangeHandler = useMemo(
    () => debounce(handelSelect, 300),
    []
  );

  //cleanup for debounce
  useEffect(() => {
    return () => {
      debouncedOnChangeHandler.cancel();
    };
  }, []);

  // fetch on search
  useEffect(() => {
    if (state.length !== 0) {
      getQueries().then((queries) => {
        setTableLoading(true);
        getPaymentList(100, 1, queries).then((res) => {
          setPayments(res.data);
        });
      });
    }
  }, [state]);

  // fetch on render
  useEffect(() => {
    fetchPayments();
  }, []);

  // update table rows on fetch
  useEffect(() => {
    if (payments) {
      setRows(payments);
      setTableLoading(false);
    }
  }, [payments]);

  const columns = [
    {
      field: 'date',
      headerName: 'DATE TIME',
      minWidth: 140,
      editable: false,
      type: 'datetime',
      flex: 1,
    },
    {
      field: 'tenantName',
      headerName: 'TENANT',
      editable: false,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'planName',
      headerName: 'PLAN',
      editable: false,
      minWidth: 65,
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'AMOUNT',
      editable: false,
      minWidth: 85,
      flex: 1,
      renderCell: (params) => PriceFormatter.format(params.value),
    },
    {
      field: 'paymentMethod',
      headerName: 'PAYMENT METHOD',
      editable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'paymentCycle',
      headerName: 'PAYMENT CYCLE',
      type: 'date',
      editable: false,
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      type: 'singleSelect',
      valueOptions: ['Subscribed', 'Not Subscribed'],
      editable: false,
      minWidth: 70,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.value === 'Paid' ? (
            <StatusChip
              text={params.value}
              color={'#8467E9'}
              bgColor={'#EAE5FB'}
              rounded={true}
            />
          ) : (
            <StatusChip
              text={params.value}
              color={'#DF3E70'}
              bgColor={'#F9E0E7'}
              rounded={true}
            />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 100,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setDrawerType('edit');
            setDrawerData(params.row);
            toggleAddPaymentDrawer();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({ open: true, type: 'delete-modal', id: params.id });
          }}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      {openModal.type === 'delete-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
          handleDelete={deletePayment}
          id={openModal.id}
        />
      )}

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openMenu}
        onClose={handleMenuClose}
        elevation={0}
        MenuListProps={{
          sx: {
            width: anchorEl && anchorEl.offsetWidth,
            padding: 0,
            border: '1px solid #E4EBF4',
            borderRadius: '4px',
            borderTop: 'none',
          },
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <input
            value={menuFilterInput}
            onChange={handleMenuFilterInput}
            type="text"
            style={{
              border: '1px solid #e3eaf3',
              outline: 'none',
              fontSize: '14px',
              borderRadius: '2px',
              height: '1.5rem',
              width: '90%',
            }}
          />
        </div>
        <div>
          <MenuList dense>
            {filterMenuList.map((value, index) => (
              <MenuItem
                key={`filter-${index}`}
                onClick={() => handleCheckedMenuFilter(value)}
                sx={{ color: '#929DA6' }}
              >
                {value}
              </MenuItem>
            ))}
          </MenuList>
        </div>
      </Menu>
      {/*------------------ Teanants Heading---------------------- */}
      {addPaymentDrawer && (
        <AddPayment
          toggleAddPaymentDrawer={toggleAddPaymentDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
          fetchPayments={fetchPayments}
        />
      )}
      <Grid
        container
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{ marginTop: 5, marginBottom: 1, fontSize: 28, color: '#3A3A50' }}
        >
          Payments
        </Typography>
      </Grid>
      <Grid>
        <CustomButtonPages
          title="+ Add payment"
          isIcon={false}
          onClick={() => {
            setDrawerType('add');
            toggleAddPaymentDrawer();
          }}
        />
      </Grid>
      {/* --------------------SEARCH---------------------- */}
      <Grid
        style={{ marginTop: '0.85rem' }}
        container
        item
        justifyContent="space-between"
        spacing={{ xs: '3px', md: '2px' }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {filterOption.map((item, index) => {
          const feild = state.find((op) => op.id === item.id);
          return (
            <>
              {item.type === 'search' && (
                <Grid item xs={12} sm={12} md={3}>
                  <div
                    style={{
                      backgroundColor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: { xs: 'start', md: 'center' },
                      height: '60px',
                      boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <SearchIcon
                        sx={{
                          color: '#b0c1c9',
                          fontSize: '28px',
                          margin: '10px',
                        }}
                      />
                      <input
                        type="text"
                        className="quickSearch"
                        placeholder="Quick search"
                        onChange={(e) =>
                          debouncedOnChangeHandler(item, e.target.value)
                        }
                        style={{
                          width: '100%',
                          fontSize: '14px',
                          boxSizing: 'border-box',
                          border: 'none',
                          outline: 'none',
                          color: '#929DA6',
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              )}

              {item.type === 'select' && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  sx={{ position: 'relative', cursor: 'pointer' }}
                  key={index}
                >
                  <div
                    style={{
                      backgroundColor: 'white',
                      height: '60px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                    }}
                    onClick={
                      findAns(item.id)
                        ? () => handelClear(item.id)
                        : () => handelOpen(item.id)
                    }
                  >
                    <Typography
                      style={{
                        color: findAns(item.id) ? '#495057' : '#929DA6',
                        fontSize: '14px',
                      }}
                    >
                      {findAns(item.id) ? findAns(item.id).ans : item.title}
                    </Typography>
                    {findAns(item.id) ? (
                      <ClearIcon
                        sx={{ fontSize: '14px', padding: '10px' }}
                        onClick={() => handelClear(item.id)}
                      />
                    ) : item.isOpen ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </div>
                  {item.isOpen && (
                    <Grid
                      style={{
                        backgroundColor: 'white',
                        position: 'absolute',
                        zIndex: 1,
                        border: '1px solid #ddd',
                        padding: '5px',
                        width: '100%',
                      }}
                      sx={
                        {
                          // width: { xs: "99.5%", md: "215px" },
                        }
                      }
                    >
                      <TextField
                        sx={{
                          width: '100%',
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            padding: '0',
                            height: '40px',
                            width: '100%',
                          },
                        }}
                        onChange={(e) =>
                          handelOnFilterOption(item.id, e.target.value)
                        }
                        value={item.search}
                        focused={false}
                      />

                      <div
                        style={
                          item.options.length > 7
                            ? {
                                maxHeight: '200px',
                                overflowY: 'scroll',
                              }
                            : {
                                maxHeight: '200px',
                              }
                        }
                      >
                        {item.search
                          ? item.options
                              .filter((op) =>
                                op
                                  .toLowerCase()
                                  .includes(item.search.toLowerCase())
                              )
                              .map((option) => (
                                <MenuItem
                                  key={option}
                                  onClick={(e) =>
                                    debouncedOnChangeHandler(item, option)
                                  }
                                  sx={{ color: '#828F9A' }}
                                >
                                  {option}
                                </MenuItem>
                              ))
                          : item.options.map((option) => (
                              <MenuItem
                                key={option}
                                sx={{ color: '#828F9A' }}
                                onClick={(e) =>
                                  debouncedOnChangeHandler(item, option)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                      </div>
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          );
        })}
      </Grid>

      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
                color: '#3A3A50',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell': {
                color: '#3A3A50',
              },
              minHeight: '60vh',
            }}
            rows={rows}
            columns={columns}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            loading={tableLoading}
            components={{
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Payments;
