import { UpnizeBackend } from "../Axios";

export const getNotificationsList = async () => {
  let response = await UpnizeBackend.get(`/notifications/all`);
  console.log(response.data);
  return response;
};

export const getCSU = async () => {
  let response = await UpnizeBackend.get(`/notifications/CSU`);
  console.log(response.data);
  return response;
};

export const getPR = async () => {
  let response = await UpnizeBackend.get(`/notifications/PR`);
  console.log(response.data);
  return response;
};

export const getSUC = async () => {
  let response = await UpnizeBackend.get(`/notifications/SUC`);
  console.log(response.data);
  return response;
};

export const getSUB = async () => {
  let response = await UpnizeBackend.get(`/notifications/SUB`);
  console.log(response.data);
  return response;
};

export const getUnSUB = async () => {
  let response = await UpnizeBackend.get(`/notifications/UnSUB`);
  console.log(response.data);
  return response;
};

export const editPR = async (body) => {
  let response = await UpnizeBackend.post(`/notifications/edit/PR`, body);
  console.log(response.data);
  return response;
};

export const editCSU = async (body) => {
  let response = await UpnizeBackend.post(`/notifications/edit/CSU`, body);
  console.log(response.data);
  return response;
};

export const editSUC = async (body) => {
  let response = await UpnizeBackend.post(`/notifications/edit/SUC`, body);
  console.log(response.data);
  return response;
};

export const editSUB = async (body) => {
  let response = await UpnizeBackend.post(`/notifications/edit/SUB`, body);
  console.log(response.data);
  return response;
};

export const editUnSub = async (body) => {
  let response = await UpnizeBackend.post(`/notifications/edit/UnSub`, body);
  console.log(response.data);
  return response;
};
