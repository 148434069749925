import { useRoutes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Notifications from "./pages/Notifications";
import Payments from "./pages/Payments";
import Plans from "./pages/Plans";
import Settings from "./pages/Settings";
import Tenant from "./pages/Tenant";
import System from "./components/System";
import Subscribers from "./pages/Subscribers";
import News from "./pages/News";
import Login from "./pages/Login/Login";

export const Routes = () => {
  const element = useRoutes(routes);

  return element;
};

const routes = [
  {
    path: "/",
    element: (
      <System>
        <Dashboard />
      </System>
    ),
  },
  {
    path: "/login",
    element: (
        <Login />
    ),
  },
  {
    path: "/Tenant",
    element: (
      <System>
        <Tenant />
      </System>
    ),
  },
  {
    path: "/payments",
    element: (
      <System>
        <Payments />
      </System>
    ),
  },
  {
    path: "/notifications",
    element: (
      <System>
        <Notifications />
      </System>
    ),
  },
  {
    path: "/plans",
    element: (
      <System>
        <Plans />
      </System>
    ),
  },
  {
    path: "/subscribers",
    element: (
      <System>
        <Subscribers />
      </System>
    ),
  },
  {
    path: "/news",
    element: (
      <System>
        <News />
      </System>
    ),
  },
  {
    path: "/settings",
    element: (
      <System>
        <Settings />
      </System>
    ),
  },
  { path: "*", element: <NotFound /> },
];
