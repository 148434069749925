import React, { useEffect, useState } from 'react';
import Drawer from '../../components/Drawer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox, useMediaQuery, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DrawerFooter from '../../components/DrawerFooter';
import { useLayoutEffect } from 'react';
import { getPlanList } from '../../Api/Admin/plan';
import { addTenant as add, editTenantById } from '../../Api/Admin/tenant';
import CustomButtonPages from '../../components/CustomButtonPages';

const AddTenant = ({
  toggleAddTenantDrawer,
  drawerType,
  drawerData = null,
  fetchTenants,
}) => {
  const [email, setEmail] = useState(null);
  const [cName, setCName] = useState(null);
  const [fName, setFName] = useState(null);
  const [lName, setLName] = useState(null);
  const [expiry, setExpiry] = useState(new Date());
  const [allPlans, setAllPlans] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [status, setStatus] = useState(null);
  const [sendWelcome, setSendWelcome] = useState(true);
  const [industry, setIndustry] = useState(null);
  const [country, setCounter] = useState(null);
  const [language, setLanguage] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const buttonProps = {
    variant: 'contained',
    size: 'large',
  };

  const fetchPlans = async () => {
    const plans = await getPlanList(100, 1).then((res) => {
      setAllPlans([...res.data]);
      return [...res.data];
    });
    return plans;
  };

  // error handling
  const [errors, setErrors] = useState({});
  const requiredFields = ['fName', 'lName', 'email', 'status'];

  const addTenant = async () => {
    let data = {
      fName,
      lName,
      email,
      status,
    };
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    console.log(errors);
    if (Object.keys(err).length === 0) {
      let formData = new FormData();
      formData.append('email', email);
      formData.append('name', cName);
      formData.append('firstName', fName);
      formData.append('lastName', lName);
      // formData.append('planId', selectedPlan.id);
      formData.append('expiresOn', expiry);
      formData.append('type', 'admin');
      formData.append('sendWelcome', sendWelcome);
      status
        ? formData.append('status', status)
        : formData.append('status', 'active');

      add(formData).then((res) => {
        toggleAddTenantDrawer();
        fetchTenants();
      });
    }
  };

  const updateTenant = async () => {
    let data = {
      fName,
      lName,
      email,
      status,
    };
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      let formData = new FormData();
      email && formData.append('email', email);
      fName && formData.append('firstName', fName);
      lName && formData.append('lastName', lName);
      selectedPlan.id && formData.append('planId', selectedPlan.id);
      expiry && formData.append('expiresOn', expiry);
      status && formData.append('status', status);
      cName && formData.append('name', cName);

      editTenantById(
        {
          User: {
            email: email,
            firstName: fName,
            lastName: lName,
          },
          planId: selectedPlan.id,
          expiresOn: expiry,
          status: status,
          name: cName,
        },
        drawerData.id
      ).then((res) => {
        toggleAddTenantDrawer();
        fetchTenants();
      });
    }
  };

  useLayoutEffect(() => {
    fetchPlans().then((plans) => {
      if (drawerType != 'add') {
        setEmail(drawerData?.User.email);
        setCName(drawerData?.name);
        setExpiry(drawerData?.expiresOn);
        setFName(drawerData?.User.firstName);
        setLName(drawerData?.User.lastName);
        setStatus(drawerData?.status);
        setIndustry(drawerData?.industry);
        setCounter(drawerData?.country);
        setLanguage(drawerData?.language);
        setTimezone(drawerData?.timezone);
        setSelectedPlan(
          plans?.filter((plan) => {
            return plan.id == drawerData.planId;
          })[0]
        );
      }
    });
  }, []);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrors({ ...errors, email: false });
  };
  const handleCName = (e) => {
    setCName(e.target.value);
  };

  const handleExpiry = (e) => {
    setExpiry(e);
  };

  return (
    <Grid container>
      {/* sx={{ width: "100vw"}} */}
      <Grid item xs={12} sm={2}>
        <Drawer
          title={drawerType === 'add' ? 'Add Tenant' : 'Edit Tenant'}
          type={drawerType === 'add' ? 'ADD' : 'EDIT'}
          toggleDrawer={toggleAddTenantDrawer}
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              paddingX: 3,
              paddingTop: 3,
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <Grid container>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Email<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    value={email}
                    type="email"
                    onChange={handleEmail}
                    helperText={errors.email ? 'Value is missing' : ' '}
                    error={errors.email}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} sm={6}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Company name
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    // disabled={drawerType !== "add" ? true : false}
                    onChange={handleCName}
                    value={cName}
                  />
                </Grid>
              </Grid>

              {drawerType === 'add' && (
                <Grid xs={12} sm={6}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginY: 1,
                      mt: { md: 4.5, xs: 1 },
                    }}
                  >
                    <Checkbox
                      checked={sendWelcome}
                      onChange={(e) => {
                        setSendWelcome(e.target.checked);
                      }}
                    ></Checkbox>
                    <Typography variant="subtitle2">
                      Send a welcome Email
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Grid xs={12} sm={drawerType === 'add' ? 6 : 12}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Status<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={['active', 'inactive', 'marked for deletion']}
                    value={status}
                    sx={{ marginBottom: '1rem', fontSize: '14px' }}
                    onChange={(e, data) => {
                      setStatus(data);
                      setErrors({ ...errors, status: false });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select"
                        size="small"
                        helperText={errors.status ? 'Value is missing' : ' '}
                        error={errors.status}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    First Name<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    value={fName}
                    onChange={(e) => {
                      setFName(e.target.value);
                      setErrors({ ...errors, fName: false });
                    }}
                    helperText={errors.fName ? 'Value is missing' : ' '}
                    error={errors.fName}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Last Name<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    value={lName}
                    onChange={(e) => {
                      setLName(e.target.value);
                      setErrors({ ...errors, lName: false });
                    }}
                    helperText={errors.lName ? 'Value is missing' : ' '}
                    error={errors.lName}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Plan
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={allPlans?.map((p) => {
                      return p;
                    })}
                    isOptionEqualToValue={(o, v) => o.name == v.name}
                    value={selectedPlan}
                    getOptionLabel={(p) => {
                      return p.name;
                    }}
                    sx={{ marginBottom: '2rem', fontSize: '14px' }}
                    onChange={(e, data) => {
                      setSelectedPlan(data);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select" size="small" />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} sm={6}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Expires on
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={expiry}
                      onChange={handleExpiry}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              {drawerType !== 'add' && (
                <>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: '#3A3A50',
                        }}
                      >
                        Country
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{
                          marginBottom: '1rem',
                          width: '100%',
                          fontSize: '14px',
                          background: '#f3efef',
                          '& .MuiOutlinedInput-root.Mui-disabled': {
                            '& > fieldset': { border: '1px solid transparent' },
                          },
                        }}
                        disabled
                        value={country}
                      />
                    </Grid>
                  </Grid>

                  <Grid xs={12} sm={6}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: '#3A3A50',
                        }}
                      >
                        Language
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{
                          marginBottom: '1rem',
                          width: '100%',
                          fontSize: '14px',
                          background: '#f3efef',
                          '& .MuiOutlinedInput-root.Mui-disabled': {
                            '& > fieldset': { border: '1px solid transparent' },
                          },
                        }}
                        value={language}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: '#3A3A50',
                        }}
                      >
                        Industry
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{
                          marginBottom: '1rem',
                          width: '100%',
                          fontSize: '14px',
                          background: '#f3efef',
                          '& .MuiOutlinedInput-root.Mui-disabled': {
                            '& > fieldset': { border: '1px solid transparent' },
                          },
                        }}
                        value={industry}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid xs={12} sm={6}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: '#3A3A50',
                        }}
                      >
                        Time Zone
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{
                          marginBottom: '1rem',
                          width: '100%',
                          fontSize: '14px',
                          background: '#f3efef',
                          '& .MuiOutlinedInput-root.Mui-disabled': {
                            '& > fieldset': { border: '1px solid transparent' },
                          },
                        }}
                        value={timezone}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {/* -----Button */}
          <DrawerFooter>
            {drawerType === 'add' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '15px',
                    justifyContent: isSmallScreen
                      ? 'space-between'
                      : 'flex-end',
                    width: '100%',
                  }}
                >
                  <CustomButtonPages
                    title="Cancel"
                    isIcon={false}
                    onClick={toggleAddTenantDrawer}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                  <CustomButtonPages
                    title="ADD"
                    isIcon={false}
                    blue={true}
                    onClick={addTenant}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                </div>
              </>
            )}
            {drawerType === 'edit' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '15px',
                    justifyContent: isSmallScreen
                      ? 'space-between'
                      : 'flex-end',
                    width: '100%',
                  }}
                >
                  <CustomButtonPages
                    title="Cancel"
                    isIcon={false}
                    onClick={toggleAddTenantDrawer}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                  <CustomButtonPages
                    title="SAVE"
                    isIcon={false}
                    blue={true}
                    onClick={updateTenant}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                </div>
              </>
            )}
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddTenant;
