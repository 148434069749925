//import { TextField, Box, Link} from '@material-ui/core';
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import React from 'react';
class Footer extends React.Component{



    render() {
    return (
        <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          color: "#656565",
        }}
      >
        <Link
          href="#"
          variant="p"
          sx={{
            paddingX: "5px",
            color: "#656565",
            textDecoration: "none",
          }}
        >
          @ 2022 Upnize
        </Link>
        <p>&nbsp;| &nbsp;</p>
        <Link
          href="https://support.upnize.com"
          variant="a"
          target="_blank"
          sx={{
            paddingX: "5px",
            color: "#656565",
            textDecoration: "none",
            "&:hover": {
              color: "blue",
            },
          }}
        >
          Need help?
        </Link>
        <p>&nbsp;|&nbsp;</p>
        <Link
          href="https://www.upnize.com/terms-of-service"
          variant="a"
          target="_blank"
          sx={{
            paddingX: "5px",
            color: "#656565",
            textDecoration: "none",
            "&:hover": {
              color: "blue",
            },
          }}
        >
          Terms of service
        </Link>
      </Box>
      )
    }
}

export default Footer;