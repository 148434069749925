import { Grid, Typography } from '@mui/material';
import CustomLimit from './CustomLimit';

const Limits = ({ limits, setLimits }) => {
  return (
    <Grid xs={12} sx={{ marginY: 1 }}>
      <CustomLimit
        name="Allowed maximum Locations"
        state={limits}
        setStateFunc={setLimits}
        attributeName="LOC"
      />
      <CustomLimit
        name="Allowed maximum Staff"
        state={limits}
        setStateFunc={setLimits}
        attributeName="ST"
      />
      <CustomLimit
        name="Allowed maximum Services"
        state={limits}
        setStateFunc={setLimits}
        attributeName="SER"
      />
      <CustomLimit
        name="Allowed maximum SMS"
        state={limits}
        setStateFunc={setLimits}
        attributeName="SMS"
      />
      <CustomLimit
        name="Allowed maximum Email"
        state={limits}
        setStateFunc={setLimits}
        attributeName="EML"
      />
      <CustomLimit
        name="Allowed maximum WhatsApp message"
        state={limits}
        setStateFunc={setLimits}
        attributeName="WAPP"
      />
    </Grid>
  );
};

export default Limits;
