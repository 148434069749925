import React, { useEffect, useLayoutEffect, useState } from 'react';
import Drawer from '../../components/Drawer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DrawerFooter from '../../components/DrawerFooter';
import { useMediaQuery, useTheme } from '@mui/material';
import { getTenantList } from '../../Api/Admin/tenant';
import { getPlanList } from '../../Api/Admin/plan';
import { addPayment as add, editPaymentById } from '../../Api/Admin/payment';
import CustomButtonPages from '../../components/CustomButtonPages';

const AddPayment = ({
  toggleAddPaymentDrawer,
  drawerType,
  drawerData = null,
  fetchPayments,
}) => {
  const [tenantsList, setTenantsList] = useState(['tent']);
  const [plansList, setPlansList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [amount, setAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentCycle, setPaymentCycle] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [paymentMethodsList, setPaymentMethodsList] = useState([
    { id: '1', label: 'Offline' },
    { id: '2', label: 'Paypal' },
    { id: '3', label: 'Credit Card' },
  ]);
  const [paymentCyclesList, setPaymentCyclesList] = useState([
    { id: '1', label: 'Monthly' },
    { id: '2', label: 'Annually' },
  ]);
  const buttonProps = {
    variant: 'contained',
    size: 'large',
  };

  // API

  async function fetchTenants() {
    const tenants = await getTenantList(100, 1).then((res) => {
      setTenantsList(res.data);
      return res.data;
    });
    return tenants;
  }

  const fetchPlans = async () => {
    const plans = await getPlanList(100, 1).then((res) => {
      setPlansList(res.data);
      return res.data;
    });

    return plans;
  };

  const [errors, setErrors] = useState({});
  const requiredFields = [
    'amount',
    'paymentMethod',
    'paymentCycle',
    'paymentDate',
    'selectedTenant',
  ];

  // handlers
  const addPayment = async () => {
    let data = {
      amount,
      paymentMethod,
      paymentCycle,
      paymentDate,
      selectedTenant,
    };
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      add({
        planId: selectedPlan.id,
        amount: +amount,
        paymentMethod,
        status: 'Paid',
        paymentCycle,
        date: paymentDate,
        tenantId: selectedTenant.TenantId,
      }).then((res) => {
        toggleAddPaymentDrawer();
        fetchPayments();
      });
    }
  };

  const editPayment = async () => {
    let data = {
      amount,
      paymentMethod,
      paymentCycle,
      paymentDate,
      selectedTenant,
    };
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      editPaymentById(
        {
          planId: selectedPlan.id,
          amount,
          paymentMethod,
          paymentCycle,
          date: paymentDate,
        },
        drawerData.id
      ).then((res) => {
        toggleAddPaymentDrawer();
        fetchPayments();
      });
    }
  };

  // initalization step
  useLayoutEffect(() => {
    if (drawerType != 'add') {
      setPaymentCycle(drawerData?.paymentCycle);
      setAmount(345);
      setPaymentDate(drawerData?.date);
      setPaymentMethod(drawerData?.paymentMethod);
      fetchPlans().then((plans) => {
        setSelectedPlan(
          plans?.filter((plan) => {
            return plan.id == drawerData.planId;
          })[0]
        );
      });
      fetchTenants().then((tenants) => {
        setSelectedTenant(
          tenants?.filter((tenant) => {
            return tenant.TenantId == drawerData.tenantId;
          })[0]
        );
      });
    } else {
      fetchTenants();
      fetchPlans();
    }
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          title={drawerType === 'add' ? 'Add Payment' : 'Edit Payment'}
          type={drawerType === 'add' ? 'ADD' : 'EDIT'}
          toggleDrawer={toggleAddPaymentDrawer}
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              paddingX: 3,
              paddingTop: 3,
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <Grid item container>
              <Grid item xs={12} sx={{ marginY: 1 }}>
                <Typography
                  sx={{
                    color: '#3A3A50',
                  }}
                >
                  Tenant<sapn style={{ color: 'red' }}>*</sapn>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  options={tenantsList.map((t) => {
                    return t;
                  })}
                  isOptionEqualToValue={(o, v) => o?.name == v?.name}
                  getOptionLabel={(o) => {
                    return o?.name;
                  }}
                  value={selectedTenant}
                  sx={{ width: '100%', marginBottom: 1.5 }}
                  onChange={(e, data) => {
                    setSelectedTenant(data);
                    setErrors({ ...errors, selectedTenant: false });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select"
                      size="small"
                      helperText={
                        errors.selectedTenant ? 'Value is missing' : ' '
                      }
                      error={errors.selectedTenant}
                    />
                  )}
                />
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Plan
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={plansList.map((p) => p)}
                    sx={{ marginBottom: 1.5 }}
                    getOptionLabel={(o) => {
                      return o?.name;
                    }}
                    isOptionEqualToValue={(o, v) => o?.name == v?.name}
                    value={selectedPlan}
                    onChange={(e, data) => {
                      setSelectedPlan(data);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select" size="small" />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} sm={6}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Amount ($)<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    sx={{ marginBottom: 1.5, width: '100%' }}
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      setErrors({ ...errors, amount: false });
                    }}
                    helperText={errors.amount ? 'Value is missing' : ' '}
                    error={errors.amount}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Payment Method<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={paymentMethodsList}
                    sx={{ marginBottom: 1.5 }}
                    isOptionEqualToValue={(o, v) => o?.label == v}
                    value={paymentMethod}
                    onChange={(e, data) => {
                      setPaymentMethod(data.label);
                      setErrors({ ...errors, paymentMethod: false });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select"
                        size="small"
                        helperText={
                          errors.paymentMethod ? 'Value is missing' : ' '
                        }
                        error={errors.paymentMethod}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} sm={6}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Payment Cycle<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={paymentCyclesList}
                    sx={{ marginBottom: 1.5 }}
                    onChange={(e, data) => {
                      setPaymentCycle(data.label);
                      setErrors({ ...errors, paymentCycle: false });
                    }}
                    isOptionEqualToValue={(o, v) => {
                      return o?.label === v;
                    }}
                    value={paymentCycle}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select"
                        size="small"
                        helperText={
                          errors.paymentCycle ? 'Value is missing' : ' '
                        }
                        error={errors.paymentCycle}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Payment date<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={paymentDate}
                      onChange={(date) => {
                        setPaymentDate(date);
                        setErrors({ ...errors, paymentDate: false });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: '100%' }}
                          size="small"
                          helperText={
                            errors.paymentDate ? 'Value is missing' : ' '
                          }
                          error={errors.paymentDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* -----Button */}
          <DrawerFooter>
            {drawerType === 'add' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '15px',
                    justifyContent: isSmallScreen
                      ? 'space-between'
                      : 'flex-end',
                    width: '100%',
                  }}
                >
                  <CustomButtonPages
                    title="Cancel"
                    isIcon={false}
                    onClick={toggleAddPaymentDrawer}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                  <CustomButtonPages
                    title="ADD"
                    isIcon={false}
                    blue={true}
                    onClick={addPayment}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                </div>
              </>
            )}
            {drawerType === 'edit' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '15px',
                    justifyContent: isSmallScreen
                      ? 'space-between'
                      : 'flex-end',
                    width: '100%',
                  }}
                >
                  <CustomButtonPages
                    title="Cancel"
                    isIcon={false}
                    onClick={toggleAddPaymentDrawer}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                  <CustomButtonPages
                    title="SAVE"
                    isIcon={false}
                    blue={true}
                    onClick={editPayment}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                </div>
              </>
            )}
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddPayment;
