import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import QuillEditor from '../../components/QuillEditor';

const Details = (props) => {
  const {
    name,
    setName,
    bookmark,
    setBookmark,
    color,
    setColor,
    orderNumber,
    setOrderNumber,
    monthlyPrice,
    setMonthlyPrice,
    discountFirstMonth,
    setDiscountFirstMonth,
    annualPrice,
    setAnnualPrice,
    discountFirstYear,
    setDiscountFirstYear,
    hiddenPlan,
    setHiddenPlan,
    quillRef,
    quillHtml,
    setQuillHtml,
    errors,
    setErrors,
  } = props;

  return (
    <>
      <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#3A3A50',
            }}
          >
            Name<sapn style={{ color: 'red' }}>*</sapn>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            sx={{ marginBottom: 1, width: '100%' }}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrors({ ...errors, name: false });
            }}
            helperText={errors.name ? 'Value is missing' : ' '}
            error={errors.name}
          />
        </Grid>
      </Grid>
      <Grid xs={12} sm={6}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#3A3A50',
            }}
          >
            Bookmark<sapn style={{ color: 'red' }}>*</sapn>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            sx={{ marginBottom: 1, width: '100%' }}
            value={bookmark}
            onChange={(e) => {
              setBookmark(e.target.value);
              setErrors({ ...errors, bookmark: false });
            }}
            helperText={errors.bookmark ? 'Value is missing' : ' '}
            error={errors.bookmark}
          />
        </Grid>
      </Grid>

      <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#3A3A50',
            }}
          >
            Color
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            type="color"
            sx={{ marginBottom: 1, width: '100%' }}
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid xs={12} sm={6}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#3A3A50',
            }}
          >
            Order Number<sapn style={{ color: 'red' }}>*</sapn>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            sx={{ marginBottom: 1, width: '100%' }}
            value={orderNumber}
            onChange={(e) => {
              setOrderNumber(e.target.value);
              setErrors({ ...errors, orderNumber: false });
            }}
            helperText={errors.orderNumber ? 'Value is missing' : ' '}
            error={errors.orderNumber}
          />
        </Grid>
      </Grid>

      <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#3A3A50',
            }}
          >
            Monthly Price ($)<sapn style={{ color: 'red' }}>*</sapn>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            sx={{ marginBottom: 1, width: '100%' }}
            value={monthlyPrice}
            onChange={(e) => {
              setMonthlyPrice(e.target.value);
              setErrors({ ...errors, monthlyPrice: false });
            }}
            helperText={errors.monthlyPrice ? 'Value is missing' : ' '}
            error={errors.monthlyPrice}
          />
        </Grid>
      </Grid>
      <Grid xs={12} sm={6}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#3A3A50',
            }}
          >
            Discount for first month (%)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            sx={{ marginBottom: 1, width: '100%' }}
            value={discountFirstMonth}
            onChange={(e) => {
              setDiscountFirstMonth(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#3A3A50',
            }}
          >
            Annually Price ($)<sapn style={{ color: 'red' }}>*</sapn>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            sx={{ marginBottom: 1, width: '100%' }}
            value={annualPrice}
            onChange={(e) => {
              setAnnualPrice(e.target.value);
              setErrors({ ...errors, annualPrice: false });
            }}
            helperText={errors.annualPrice ? 'Value is missing' : ' '}
            error={errors.annualPrice}
          />
        </Grid>
      </Grid>
      <Grid xs={12} sm={6}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#3A3A50',
            }}
          >
            Discount for first year (%)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            sx={{ marginBottom: 1, width: '100%' }}
            value={discountFirstYear}
            onChange={(e) => {
              setDiscountFirstYear(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
          paddingX: 2,
          paddingY: 1,
          marginY: 2,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: '#3A3A50',
          }}
        >
          Hidden Plan
        </Typography>
        <Switch
          size="small"
          defaultChecked
          sx={{ marginLeft: 2 }}
          onChange={(e) => {
            setHiddenPlan(e.target.checked);
          }}
          checked={hiddenPlan}
        />
      </Grid>

      <Grid xs={12}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#3A3A50',
            }}
          >
            Description
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <QuillEditor
            quillRef={quillRef}
            onChange={({ html }) => {
              setQuillHtml(html);
            }}
            value={quillHtml}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Details;
