import { Grid, Typography } from '@mui/material';

import classes from './Limits.module.css';

const CustomLimit = ({ name, state, setStateFunc, attributeName }) => {
  return (
    <Grid className={classes.limitContainer}>
      <Typography
        variant="subtitle1"
        style={{
          backgroundColor: '#FFF',
          paddingRight: '10px',
          zIndex: 2,
          color: '#3A3A50',
        }}
      >
        {name}
      </Typography>
      <input
        type="text"
        value={state[attributeName]}
        onChange={(e) =>
          setStateFunc((prevState) => ({
            ...prevState,
            [attributeName]: e.target.value,
          }))
        }
        style={{
          outline: 'none',
          height: '36px',
          width: '50px',
          borderRadius: '2px',
          border: '1px solid #E3EAF3',
          fontSize: '14px',
          color: '#292D32',
          textAlign: 'center',
        }}
      />
    </Grid>
  );
};

export default CustomLimit;
