import axios from "axios";
import { deleteDelay } from "../providers/SnackbarProvider";
import { callSnackbar } from "../redux/slices/snackbar.slice";
import { store } from "../redux/store";
import { sleep } from "../utils/sleep";

let headersList = {
  Accept: "*/*",
};

export let controller;

export const abortRequest = () => {
  const newController = new AbortController();
  controller = newController;
  return newController;
};

// Public instance
export const UpnizePublicBackend = axios.create({
  baseURL: process.env.REACT_APP_UPNIZE_PUBLIC_BACKEND_URL,
  headers: headersList,
  // withCredentials: true,
});

// axios admin instance
export const UpnizeBackend = axios.create({
  baseURL: process.env.REACT_APP_UPNIZE_BACKEND_URL,
  headers: headersList,
  // withCredentials: true,
});

UpnizeBackend.interceptors.request.use(
  async (req) => {
    if (req.method === "delete") {
      store.dispatch(
        callSnackbar({
          message: "Deleting record",
          type: "warning",
        })
      );
      await sleep(deleteDelay);
    }
    return controller ? { ...req, signal: controller.signal } : req;
  },
  function (error) {
    console.log("error", error);

    store.dispatch(callSnackbar({ message: error.message, type: "error" }));

    return Promise.reject(error);
  }
);

UpnizeBackend.interceptors.response.use(
  (res) => {
    if (res.config.method !== "get" && typeof res.data == "string") {
      store.dispatch(
        store.dispatch(callSnackbar({ message: res?.data, type: "success" }))
      );
    }
    return res;
  },
  function (error) {
    console.log("error", error);
    if (error.code === "ERR_CANCELED") {
      store.dispatch(callSnackbar({ message: "Canceled", type: "success" }));
      controller = null;
    } else if (error.status === 401) {
      window.location.replace("/login");
      localStorage.removeItem("user");
    } else {
      store.dispatch(callSnackbar({ message: error.message, type: "error" }));
    }
    return Promise.reject(error);
  }
);
