import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';
import personDashboardIcon from '../../assets/images/person-dashboard-icon.svg';
import timeDashboardIcon from '../../assets/images/time-dashboard-icon.svg';
import walletDashboardIcon from '../../assets/images/wallet-dashboard-icon.svg';
import refreshDashboardIcon from '../../assets/images/refresh-dashboard-icon.svg';
import { getDashboard } from '../../Api/Admin/dashboard';
import PriceFormatter from '../../utils/PriceFormatter';
import CircularProgress from '@mui/material/CircularProgress';
import TotalPeople from '../../components/Dashboard/TotalPeople';
import NewestCompanies from '../../components/Dashboard/NewestCompanies';
import className from './Dashboard.module.css';
import ActiveCompanies from '../../components/Dashboard/ActiveCompanies';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CachedIcon from '@mui/icons-material/Cached';

const Dashboard = () => {
  const [data, setData] = useState(null);

  // API
  useEffect(() => {
    getDashboard().then((res) => {
      setData(res.data);
    });
  }, []);

  const iconStyle = {
    border: '1px solid #1976d2',
    color: '#1976d2',
    width: '40px',
    p: '10px',
    height: '40px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{ marginTop: 5, marginBottom: 1, fontSize: 28, color: '#3A3A50' }}
        >
          Dashboard
        </Typography>
      </Grid>

      <Grid
        item
        style={{
          padding: '1.5rem 0',
          borderTop: '1px solid #e3eaf3',
          borderBottom: '1px solid #e3eaf3',
        }}
        container
        sx={{ marginY: 3 }}
      >
        <Grid item xs={12} sm={6} lg={3} sx={{ padding: 1 }}>
          <Card>
            <CardContent sx={{ display: 'flex' }}>
              {/* <img src={personDashboardIcon} alt="person-dashboard-icon" /> */}
              <Box
                sx={{
                  color: '#1976d2',
                  width: '80px',
                  height: '80px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'start',
                }}
              >
                <PersonIcon sx={iconStyle} />
              </Box>
              <Box sx={{ marginTop: 1, marginLeft: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: '6vw', sm: '3vw', md: '1.5vw' },
                    fontWeight: 600,
                    color: '#3A3A50',
                  }}
                  color="text.secondary"
                >
                  {data ? data[0].countOfTenants : <CircularProgress />}
                </Typography>
                <Typography
                  sx={{ fontSize: 12, color: '#828F9A' }}
                  color="text.secondary"
                >
                  TENANTS
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} sx={{ padding: 1 }}>
          <Card>
            <CardContent sx={{ display: 'flex' }}>
              <Box
                sx={{
                  color: '#1976d2',
                  width: '80px',
                  height: '80px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'start',
                }}
              >
                <AccessTimeIcon sx={iconStyle} />
              </Box>{' '}
              <Box sx={{ marginTop: 1, marginLeft: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: '6vw', sm: '3vw', md: '1.5vw' },
                    fontWeight: 600,
                    color: '#3A3A50',
                  }}
                  color="text.secondary"
                >
                  {data ? data[1].countOfAppointments : <CircularProgress />}
                </Typography>
                <Typography
                  sx={{ fontSize: 12, color: '#828F9A' }}
                  color="text.secondary"
                >
                  APPOINTMENTS
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} sx={{ padding: 1 }}>
          <Card>
            <CardContent sx={{ display: 'flex' }}>
              <Box
                sx={{
                  color: '#1976d2',
                  width: '80px',
                  height: '80px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'start',
                }}
              >
                <AccountBalanceWalletIcon sx={iconStyle} />
              </Box>{' '}
              <Box sx={{ marginTop: 1, marginLeft: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: '6vw', sm: '3vw', md: '1.5vw' },
                    fontWeight: 600,
                    color: '#3A3A50',
                  }}
                  color="text.secondary"
                >
                  {data ? (
                    PriceFormatter.format(data[5].monthsPayment)
                  ) : (
                    <CircularProgress />
                  )}
                </Typography>
                <Typography
                  sx={{ fontSize: 12, color: '#828F9A' }}
                  color="text.secondary"
                >
                  INCOME OF THE MONTH
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} sx={{ padding: 1 }}>
          <Card>
            <CardContent sx={{ display: 'flex' }}>
              <Box
                sx={{
                  color: '#1976d2',
                  width: '80px',
                  height: '80px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'start',
                }}
              >
                <CachedIcon sx={iconStyle} />
              </Box>{' '}
              <Box sx={{ marginTop: 1, marginLeft: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: '6vw', sm: '3vw', md: '1.5vw' },
                    fontWeight: 600,
                    color: '#3A3A50',
                  }}
                  color="text.secondary"
                >
                  {data ? (
                    PriceFormatter.format(data[6].prevMonthsPayment)
                  ) : (
                    <CircularProgress />
                  )}
                </Typography>
                <Typography
                  sx={{ fontSize: 12, color: '#3A3A50' }}
                  color="text.secondary"
                >
                  INCOME OF THE PREVIOUS MONTH
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <TotalPeople users={data && data[2]?.users ? data[2]?.users : []} />
      </Grid>

      <div className={className.companiesWigetContainer}>
        <NewestCompanies
          companiesProps={
            data && data[3]?.latestAddedCompanies
              ? data[3]?.latestAddedCompanies
              : []
          }
        />
        <ActiveCompanies
          companiesProps={
            data && data[4]?.latestActiveCompanies
              ? data[4]?.latestActiveCompanies
              : []
          }
        />
      </div>
    </Grid>
  );
};

export default Dashboard;
