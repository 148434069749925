import {
  Alert,
  Box,
  Button,
  IconButton,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { abortRequest } from "../Api/Axios";
import { clearSnackbar, selectSnackbar } from "../redux/slices/snackbar.slice";
import CloseIcon from "@mui/icons-material/Close";
import { resolveDeletingPromise, toId } from "../utils/sleep";

export const deleteDelay = 5000;

function SnackbarProvider({ children }) {
  const dispatch = useDispatch();
  const state = useSelector(selectSnackbar);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(clearSnackbar());
  };

  const handleUndo = () => {
    const ctrl = abortRequest();
    ctrl.abort();
    clearTimeout(toId);
    resolveDeletingPromise();
    dispatch(clearSnackbar());
  };

  const handleReload = () => {
    window.location.reload();
  };

  const action = (
    <React.Fragment>
      {state.type === "warning" && (
        <Button
          sx={{
            color: "white",
            mt: 2,
            border: "white 1px solid",
            ":hover": { border: "white 1px solid" },
          }}
          size="small"
          onClick={handleUndo}
        >
          UNDO
        </Button>
      )}
      {state.type === "error" && (
        <Button
          sx={{
            color: "white",
            mt: 2,
            border: "white 1px solid",
            ":hover": { border: "white 1px solid" },
          }}
          size="small"
          onClick={handleReload}
        >
          RELOAD
        </Button>
      )}
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={deleteDelay}
        open={state.isOpen}
        onClose={handleClose}
        sx={{
          maxWidth: "220px",
          minWidth: "220px",
          width: "220px",
          left: "10px !important",
        }}
      >
        <Alert
          severity={state.type}
          elevation={6}
          icon={false}
          variant="filled"
          sx={{ display: "flex", alignItems: "center", width: "220px" }}
          action={
            <Box>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          }
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            {state.message}
          </Box>
          {action}
        </Alert>
      </Snackbar>
      {children}
    </>
  );
}

export default SnackbarProvider;
