import { Box } from "@mui/material";
import React from "react";

const types = {
  appointmentReceived: {
    color: "#509F8A",
    bgColor: "#84FFDE",
    text: "Received",
  },
  appointmentApproved: {
    color: "#82D376",
    bgColor: "#E9F7E8",
    text: "Approved",
  },
  appointmentCancelled: {
    color: "#DF3E70",
    bgColor: "#F9E0E7",
    text: "Cancelled",
  },
  appointmentPending: { color: "#EA9B7F", bgColor: "#FBEEE9", text: "Pending" },
  appointmentOnTheWay: {
    color: "#EA9B7F",
    bgColor: "#FBEEE9",
    text: "On the way",
  },
  appointmentCompleted: {
    color: "#8467E9",
    bgColor: "#EAE5FB",
    text: "Completed",
  },
  appointmentRejected: {
    color: "#929DA6",
    bgColor: "#EDEEF0",
    text: "Rejected",
  },
  appointmentNoShow: { color: "#F0912C", bgColor: "#FDE8D5", text: "NoShow" },
};

function StatusChip({ color, bgColor, text, type, rounded, width, opacity }) {
  if (type) {
    return (
      <Box
        sx={{
          color: types[type].color,
          backgroundColor: types[type].bgColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "25px",
          fontSize: "14px",
          padding: "0 .8rem",
          minWidth: "24px",
          lineHeight: "14px",
          borderRadius: "15px",
          width: width ? width : "fit",
          opacity,
        }}
      >
        {text ? text : types[type].text}
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          color,
          backgroundColor: bgColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "25px",
          fontSize: "14px",
          padding: "0 .8rem",
          minWidth: "24px",
          lineHeight: "14px",
          borderRadius: rounded ? "15px" : "0",
          width: width ? width : "fit",
          opacity,
        }}
      >
        {text}
      </Box>
    );
  }
}

export default StatusChip;
