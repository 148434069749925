import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useState, useEffect, useMemo } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import AddTenant from './AddTenant';
import CustomTableFooter from '../../components/CustomTableFooter';
import Modal from '../../components/Modal';
import StatusChip from '../../components/StatusChip';
import { deleteTenantById, getTenantList } from '../../Api/Admin/tenant';
import CircularProgress from '@mui/material/CircularProgress';
import { debounce } from 'lodash';
import CustomButtonPages from '../../components/CustomButtonPages';

const row = [
  {
    id: 1,
    domain: 'tenant',
    firstName: 'Alexander',
    lastName: ' Mathew',
    email: 'alexander123@gmail.com',
    planId: 'pro',
    expiresOn: new Date(),
    status: 'Subscribed',
  },
  {
    id: 2,
    domain: 'tenant',
    firstName: 'Alexander',
    lastName: ' Mathew',
    email: 'alexander123@gmail.com',
    planId: 'pro',
    expiresOn: new Date(),
    status: 'Subscribed',
  },
  {
    id: 4,
    domain: 'tenant',
    firstName: 'Alexander',
    lastName: ' Mathew',
    email: 'alexander123@gmail.com',
    planId: 'pro',
    expiresOn: new Date(),
    status: 'not subscribed',
  },
];

const Tenant = () => {
  const [addTenantDrawer, setAddTenantDrawer] = useState(false);
  const [tenants, setTenants] = useState();
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [search, setSearch] = useState(null);

  const toggleAddTenantDrawer = () => {
    setAddTenantDrawer(!addTenantDrawer);
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = (id) => {
    setOpenModal({ open: true, type: 'delete-modal', id });
  };

  // API

  const fetchTenants = async () => {
    setTableLoading(true);
    getTenantList(100, 1).then((res) => {
      setTenants(res.data);
    });
  };

  // fetch on render
  useEffect(() => {
    if (!tenants) {
      fetchTenants();
    }
  }, []);

  // debounced search event
  const debouncedOnChangeHandler = useMemo(
    () =>
      debounce((e) => {
        setSearch(e.target.value);
      }, 300),
    []
  );

  //cleanup for debounce
  useEffect(() => {
    return () => {
      debouncedOnChangeHandler.cancel();
    };
  }, []);

  // fetch on search
  useEffect(() => {
    if (typeof search === 'string') {
      if (search.length > 3) {
        setTableLoading(true);
        getTenantList(100, 1, search).then((res) => {
          setTenants(res.data);
        });
      } else {
        fetchTenants();
      }
    }
  }, [search]);

  // update table rows on fetch
  useEffect(() => {
    if (tenants) {
      setRows(tenants);
      setTableLoading(false);
    }
  }, [tenants]);

  const deleteTenant = async (id) => {
    deleteTenantById(id).then((res) => {
      fetchTenants();
    });
  };

  const columns = [
    {
      field: 'name',
      headerName: 'FULL NAME',
      editable: false,
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        return params.row.name;
      },
    },
    {
      field: 'email',
      valueGetter: (params) => params.row.Users.email,
      headerName: 'EMAIL',
      type: 'email',
      editable: false,
      minWidth: 220,
      flex: 1,
    },
    {
      field: 'planName',
      headerName: 'PLAN',
      editable: false,
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'expiresOn',
      headerName: 'EXPIRES IN',
      type: 'date',
      editable: false,
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      type: 'singleSelect',
      valueOptions: ['Subscribed', 'Not Subscribed'],
      editable: false,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.value === 'Subscribed' ? (
            <StatusChip
              text={params.value}
              color={'#8467E9'}
              bgColor={'#EAE5FB'}
              rounded={true}
            />
          ) : (
            <StatusChip
              text={params.value}
              color={'#DF3E70'}
              bgColor={'#F9E0E7'}
              rounded={true}
            />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 100,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label="Payment history"
          onClick={() => {}}
          showInMenu
        />,
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setDrawerType('edit');
            setDrawerData(params.row);
            toggleAddTenantDrawer();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Send welcome email"
          onClick={() => {
            setOpenModal({
              open: true,
              type: 'SEND_WELCOME_EMAIL',
              id: params.id,
              email: params.row.Users.email,
            });
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Download Full Report (.CSV)"
          onClick={() => {
            setOpenModal({
              open: true,
              type: 'DOWNLOAD_FULL_REPORT',
              id: params.id,
              email: params.row.Users.email,
            });
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({
              open: true,
              type: 'delete-modal',
              id: params.row.id,
            });
          }}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      {openModal.type === 'delete-modal' ? (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
          handleDelete={deleteTenant}
          id={openModal.id}
        />
      ) : (
        <Modal
          handleOpenModal={handleOpenModal}
          type={openModal.type}
          open={openModal.open}
          handleDelete={deleteTenant}
          id={openModal.id}
          email={openModal.email}
        />
      )}

      {addTenantDrawer && (
        <AddTenant
          toggleAddTenantDrawer={toggleAddTenantDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
          fetchTenants={fetchTenants}
        />
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{ marginTop: 5, marginBottom: 1, fontSize: 28, color: '#3A3A50' }}
        >
          Tenants
        </Typography>
      </Grid>
      <Grid>
        <CustomButtonPages
          title="+ Add tenant"
          isIcon={false}
          onClick={() => {
            setDrawerType('add');
            toggleAddTenantDrawer();
          }}
        />
      </Grid>
      {/* --------------------SEARCH------------------------------- */}
      <Grid
        item
        xs={12}
        sx={{
          marginTop: { xs: '15px' },
          paddingBottom: { xs: '30px' },
          borderBottom: { xs: '1px solid #e3eaf3' },
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            height: '60px',
            borderRadius: '2px',
            boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '60px',
            }}
          >
            <SearchIcon sx={{ color: '#b0c1c9', fontSize: '28px' }} />
          </div>
          <input
            style={{
              height: '100%',
              width: '100%',
              fontSize: '14px',
              boxSizing: 'border-box',
              border: 'none',
              outline: 'none',
              color: '#292d32',
            }}
            type="text"
            placeholder="Quick search"
            onChange={debouncedOnChangeHandler}
          />
        </div>
      </Grid>
      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
                color: '#3A3A50',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell': {
                color: '#3A3A50',
              },
              minHeight: '60vh',
            }}
            rows={rows}
            columns={columns}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            loading={tableLoading}
            autoHeight
            components={{
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Tenant;
