import General from "./General";
import PaymentMethods from "./PaymentMethods";

const GeneralSettings = ({ subTab, fetchCurrentSettings, currentSettings }) => {
  return (
    <>
      {subTab === "general" && (
        <General
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {subTab === "payment-methods" && (
        <PaymentMethods
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
    </>
  );
};

export default GeneralSettings;
