import { useRef, useState } from "react";
import { UpnizeBackend } from "../Api/Axios";

const useUploadFile = () => {

  const uploadedFile = useRef();

  const [file, setFile] = useState();
  const [isDragOver, setIsDragOver] = useState(false);

    const handleUploadFileToAPI = async (endpoint) => {
      try {
        const formData = new FormData();
        formData.append('file', file);
        let response = await UpnizeBackend.post(
          endpoint,formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        console.log(response);
        return response;
      } catch(err) {
        console.log(err);

        throw err;
      }
    }

    const handleUpload = () => {
      uploadedFile.current.click();
    };
  
    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragOver(false);
    };
    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragOver(false);
    };
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragOver(true);
    };
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { files } = e.dataTransfer;
  
      if (files && files.length) {
        console.log(files);
        setFile(files[0]);
      }
      console.log('uploaded');
      setIsDragOver(false);
    };
  
    const handleOnChange = (e) => {
      setFile(e.target.files[0]);
    };

    const downloadCSVExample = async (endpoint) => {

        const res = await fetch(
          `${process.env.REACT_APP_UPNIZE_BACKEND_URL}${endpoint}`,
          {
            method: 'GET',
          }
        )
          .then((res) => res.blob())
          .then((data) => {
            const csvData = new Blob([data], {
              type: 'text/csv;charset=utf-8;',
            });
            let file = URL.createObjectURL(csvData);
            let a = document.createElement('a');
            a.download = 'template.csv';
            a.href = file;
            a.click();
          }).catch((err) => {
        console.log(err);
      })
    }

    return {
      uploadedFile,
      handleUploadFileToAPI: (endpoint) => handleUploadFileToAPI(endpoint),
      handleUpload: () => handleUpload(),
      handleDragEnter: (e) => handleDragEnter(e),
      handleDragLeave: (e) => handleDragLeave(e),
      handleDragOver: (e) => handleDragOver(e),
      handleDrop: (e) => handleDrop(e),
      handleOnChange: (e) => handleOnChange(e),
      downloadCSVExample: (endpoint) => (e) => downloadCSVExample(endpoint)
    }
}

export default useUploadFile