import back from "../../assets/images/admin_auth_background.png";
import { Grid, Container } from "@mui/material";
import Footer from "../../components/footer";
import CustomtextField from "../../components/CustomTextField/index";
import CustomButton from "../../components/CustomButton";
import { React, useState } from "react";
import CustomProgress from "../../components/ProgressBar";
import { login } from "../../Api/common/users";
import { useNavigate } from "react-router-dom";

function Login() {
  var widthPercent = window.innerWidth > 750 ? "60vh" : "40vh";
  let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [email, setEmail] = useState();
  const [validEmail, setValidEmail] = useState(true);
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // API

  const handleLogin = () => {
    if (email.match(regexEmail)) {
      setLoading(true);
      setValidEmail(true);
      login({ email, password }).then((res) => {
        console.log(res);
        if (res.status === 200) {
          localStorage.setItem("user", JSON.stringify(res.data));
          navigate("/");
        }
      });
    } else {
      setValidEmail(false);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${back})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "98vh",
        }}
      >
        <div>
          <Container component="section" maxWidth="lg">
            <Grid
              lg={12}
              item
              container
              alignItems="stretch"
              style={{
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: widthPercent,
              }}
            >
              <div
                style={{
                  boxShadow: "1px 2px 9px #C0C0C0",
                  padding: "2em",
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                  Login
                </span>
                <br />

                <br />
                <CustomtextField
                  customData={{ label: "Email" }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  error={email == "" || !validEmail}
                  helperText={
                    email == ""
                      ? "Please enter an email"
                      : !validEmail
                      ? "Invalid Email Format"
                      : ""
                  }
                  style={{ marginBottom: "5%" }}
                />
                <CustomtextField
                  customData={{ label: "Password" }}
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  error={password == ""}
                  style={{ marginBottom: "5%" }}
                />
                {loading ? (
                  <CustomProgress />
                ) : (
                  <CustomButton label="Log in" onClick={handleLogin} />
                )}
              </div>
            </Grid>
            <Footer />
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Login;
