import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes";
import "./assets/styles/App.css";
import SnackbarProvider from "./providers/SnackbarProvider";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { CookiesProvider } from "react-cookie";

function App() {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <SnackbarProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </SnackbarProvider>
      </Provider>
    </CookiesProvider>
  );
}

export default App;
