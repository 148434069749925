export let resolveDeletingPromise, rejectDeletingPromise;
export let toId;

export const sleep = async (t) => {
  return await new Promise((res, rej) => {
    resolveDeletingPromise = res;
    rejectDeletingPromise = rej;
    const timeoutId = setTimeout(res, t);
    toId = timeoutId;
  });
};
