import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: null,
  type: null,
  isOpen: false,
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    callSnackbar: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.isOpen = true;
    },
    clearSnackbar: (state, action) => {
      state.isOpen = false;
    },
  },
});

export const { callSnackbar, clearSnackbar } = snackbarSlice.actions;

export const selectSnackbar = (state) => state.snackbar;

export default snackbarSlice.reducer;
