import { UpnizePublicBackend } from '../Axios';

export const getUser = async (id) => {
  let response = await UpnizePublicBackend.get(`/users/byId/${id}`);
  return response;
};

export const login = async (body) => {
  let response = await UpnizePublicBackend.post(`/users/login/admin`, body);
  return response;
};

export const logout = async () => {
  let response = await UpnizePublicBackend.get(`/users/logout`);
  return response;
};
