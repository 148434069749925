import { Grid, Switch, TextField, Typography } from '@mui/material';
import { useState } from 'react';

const Service = () => {
  const [collapseServiceUnderACategory, setCollapseServiceUnderACategory] =
    useState(false);

  return (
    <>
      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid xs={12}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: '#3A3A50',
              }}
            >
              Mode
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder=""
              // value={urlOfFinishBookingButton}
              // onChange={(e) => setUrlOfFinishBookingButton(e.target.value)}
              id="outlined-basic"
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: '1rem',
                width: '100%',
                fontSize: '14px',
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: '#3A3A50',
              }}
            >
              Client ID
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder=""
              // value={urlOfFinishBookingButton}
              // onChange={(e) => setUrlOfFinishBookingButton(e.target.value)}
              id="outlined-basic"
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: '1rem',
                width: '100%',
                fontSize: '14px',
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: '#3A3A50',
              }}
            >
              Client secret
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder=""
              // value={urlOfFinishBookingButton}
              // onChange={(e) => setUrlOfFinishBookingButton(e.target.value)}
              id="outlined-basic"
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: '2rem',
                width: '100%',
                fontSize: '14px',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Service;
