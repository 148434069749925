export const tagsData = [
  {
    header: "Tenant Info:",
    values: [
      "{{tenant_id}}",
      "{{tenant_full_name}}",
      "{{tenant_email}}",
      "{{tenant_password}}",
      "{{tenant_registration_date}}",
      "{{url_to_complete_signup}}",
      "{{url_to_reset_password}}",
      "{{tenant_domain}}",
    ],
  },
  {
    header: "Plan & Billing:",
    values: [
      "{{subscription_expires_in}}",
      "{{plan_id}}",
      "{{plan_name}}",
      "{{plan_color}}",
      "{{plan_description}}",
      "{{payment_amount}}",
      "{{payment_method}}",
      "{{payment_cycle}}",
    ],
  },
  {
    header: "Company Info:",
    values: [
      "{{company_name}}",
      "{{company_address}}",
      "{{company_phone_number}}",
      "{{company_website}}",
      "{{company_image_url}}",
    ],
  },
];
