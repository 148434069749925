import { UpnizeBackend } from "../Axios";

export const getSubscriberList = async (rows, page, searchQuery) => {
  let response = await UpnizeBackend.get(
    `/subscribers/list?Rows=${rows}&page=${page}${
      searchQuery ? `&keyword=${searchQuery}` : ""
    }`
  );
  console.log(response.data);
  return response;
};

export const getSubscriberById = async (id) => {
  let response = await UpnizeBackend.get(`/subscribers/byId/${id}`);
  console.log(response.data);
  return response;
};

export const addSubscriber = async (body) => {
  let response = await UpnizeBackend.post(`/subscribers/add`, body);
  console.log(response.data);
  return response;
};

export const editSubscriberById = async (body, id) => {
  let response = await UpnizeBackend.put(`/subscribers/edit/${id}`, body);
  console.log(response.data);
  return response;
};

export const deleteSubscriberById = async (id) => {
  let response = await UpnizeBackend.delete(`/subscribers/delete/byId/${id}`);
  console.log(response.data);
  return response;
};

export const unSubscribe = async (id) => {
  let response = await UpnizeBackend.delete(`/subscribers/unsubscribe/${id}`);
  console.log(response.data);
  return response;
};
