import { Grid, useMediaQuery } from "@mui/material";
import { useLayoutEffect } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getSettings } from "../../Api/Admin/settings";
import AccountSettings from "../../components/Settings/AccountSettings";
import GeneralSettings from "../../components/Settings/GeneralSettings";
import LanguageSettings from "../../components/Settings/LanguageSettings";
import PaymentSettings from "../../components/Settings/PaymentSettings";
import SettingsSidebar from "../../components/SettingsSidebar";

const Settings = () => {
  const [activeTab, setActiveTab] = useState({
    main: "general-settings",
    sub: null,
  });
  const [openMenu, setOpenMenu] = useState(false);
  const mediaQuery1250 = useMediaQuery("(min-width:1250px)");
  const [currentSettings, setCurrentSettings] = useState(null);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleActiveTab = (activeTab, activeSubTab = null) => {
    setActiveTab({ main: activeTab, sub: activeSubTab });
  };

  useEffect(() => {
    setOpenMenu(false);
  }, [activeTab]);

  // API

  const fetchCurrentSettings = async () => {
    getSettings().then((res) => {
      setCurrentSettings(res.data);
    });
  };

  useEffect(() => {
    // fetchCurrentSettings();
    setCurrentSettings({})
  }, []);

  return (
    <Grid container xs={12} style={{ position: "relative" }}>
      <SettingsSidebar
        mediaQuery1250={mediaQuery1250}
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        openMenu={openMenu}
        handleOpenMenu={handleOpenMenu}
      />
      <Grid
        sm={12}
        md={mediaQuery1250 ? 9 : 8}
        sx={{ padding: { md: "0 40px", xs: "0 32px" }, width: "100%" }}
      >
        {activeTab.main === "general-settings" && (
          <GeneralSettings
            fetchCurrentSettings={fetchCurrentSettings}
            currentSettings={currentSettings}
          />
        )}
        {activeTab.main === "payment-settings" && (
          <PaymentSettings
            subTab={activeTab.sub}
            fetchCurrentSettings={fetchCurrentSettings}
            currentSettings={currentSettings}
          />
        )}
        {activeTab.main === "language-settings" && (
          <LanguageSettings
            activeTab={activeTab}
            fetchCurrentSettings={fetchCurrentSettings}
            currentSettings={currentSettings}
          />
        )}
        {activeTab.main === "account-settings" && (
          <AccountSettings
            subTab={activeTab.sub}
            fetchCurrentSettings={fetchCurrentSettings}
            currentSettings={currentSettings}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Settings;
