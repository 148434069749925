import Grid from '@mui/material/Grid';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useState, useEffect, useMemo } from 'react';
import CustomTableFooter from '../../CustomTableFooter';
import { getTenantList } from '../../../Api/Admin/tenant';
import classes from './ActiveCompanies.module.css';
const ActiveCompanies = ({ companiesProps = [] }) => {
  const [companies, setCompanies] = useState();
  const [selectionModel, setSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    setCompanies(companiesProps);
  }, [companiesProps]);

  // update table rows on fetch
  useEffect(() => {
    if (companies?.length > 0) {
      setRows(companies);
      setTableLoading(false);
    }
  }, [companies]);

  const columns = [
    {
      field: 'companyName',
      headerName: 'Company Name',
      editable: false,
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        return params.row.name;
      },
    },
    {
      field: 'createdAt',
      valueGetter: (params) => params.row.createdAt,
      headerName: 'Created At',
      type: 'date',
      editable: false,
      minWidth: 220,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 100,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label="Payment history"
          onClick={() => {}}
          showInMenu
        />,
      ],
    },
  ];

  const dataGridStyle = useMemo(
    () => ({
      border: 0,
      '& .MuiDataGrid-columnHeaderTitle': {
        fontSize: '12px',
        fontWeight: '900 !important',
        color: '#3A3A50',
      },
      '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
        backgroundColor: 'white',
        border: 'none',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-cell': {
        color: '#3A3A50',
      },
      minHeight: '70vh',
    }),
    []
  );

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <h2
          style={{
            color: '#3A3A50',
          }}
        >
          Latest Active Companies
        </h2>
        <Grid className={classes.dataGridContainer}>
          <div style={{ width: '100%' }}>
            <DataGrid
              hideFooterPagination={true}
              sx={dataGridStyle}
              rows={rows.slice(0, 10)}
              columns={columns}
              // checkboxSelection
              getRowId={(row) => row?.createdAt}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              loading={tableLoading}
              autoHeight
              components={{
                Footer: () => (
                  <CustomTableFooter selectionModel={selectionModel} />
                ),
              }}
            />
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default ActiveCompanies;
