import { useState } from "react";
import { Box, Grid } from "@mui/material";

import Language from "./Language";
import Translate from "./Translate";
import { useEffect } from "react";



const LanguageSettings = ({activeTab}) => {

  const [translateLanguage, setTranslateLanguage] = useState({open: false, name: ""})

  const handleTranslateLanguage = (languageName) => {
    setTranslateLanguage({name: languageName, open: true})
  }

  useEffect(() => {
    setTranslateLanguage({open: false, name: ""})
  }, [activeTab])

  return (
    <Box>
      {
        translateLanguage.open && <Translate translateLanguage={translateLanguage} />    
      }
      {
        !translateLanguage.open && <Language handleTranslateLanguage={handleTranslateLanguage} />
      }
    </Box>
  );
};

export default LanguageSettings;
