import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import CustomTableFooter from '../../components/CustomTableFooter';
import Modal from '../../components/Modal';
import StatusChip from '../../components/StatusChip';
import {
  deleteSubscriberById,
  getSubscriberList,
} from '../../Api/Admin/subscribers';
import { CircularProgress } from '@mui/material';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import { CSVLink } from 'react-csv';
import CustomButtonPages from '../../components/CustomButtonPages';
import CustomTooltip from '../../components/CustomTooltip';

const row = [
  {
    id: 1,
    type: 'Company',
    companyName: 'dsada',
    email: 'alexander123@gmail.com',
    phone: '+2102302193213',
    firstname: 'Alexander',
    lastname: 'Mathew',
    industry: ['Beauty', 'Health care'],
    country: 'Sweden',
    city: 'Stockholm',
    status: 'Subscribed',
  },
  {
    id: 2,
    type: 'tenant',
    companyName: 'BMW',
    email: 'alexander123@gmail.com',
    phone: '+2102302193213',
    firstname: 'Alexander',
    lastname: 'Mathew',
    industry: ['Barber'],
    country: 'Sweden',
    city: 'Stockholm',
    status: 'Not Subscribed',
  },
  {
    id: 3,
    type: 'tenant',
    companyName: 'dsada',
    email: 'alexander123@gmail.com',
    phone: '+2102302193213',
    firstname: 'Alexander',
    lastname: 'Mathew',
    industry: ['Beauty', 'Health care'],
    country: 'Sweden',
    city: 'Stockholm',
    status: 'Subscribed',
  },
];

const subscribersHeaders = [
  { label: 'Type', key: 'type' },
  { label: 'Company Name', key: 'cName' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phone' },
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastname' },
  { label: 'Industry', key: 'industry' },
  { label: 'Country', key: 'country' },
  { label: 'City', key: 'city' },
  { label: 'Status', key: 'status' },
];

const Subscribers = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [search, setSearch] = useState(null);

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  // API
  const fetchSubscribers = async () => {
    getSubscriberList(100, 1).then((res) => {
      setSubscribers(res.data);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchSubscribers();
  }, []);

  // debounced search event
  const debouncedOnChangeHandler = useMemo(
    () =>
      debounce((e) => {
        setSearch(e.target.value);
      }, 300),
    []
  );

  //cleanup for debounce
  useEffect(() => {
    return () => {
      debouncedOnChangeHandler.cancel();
    };
  }, []);

  // fetch on search
  useEffect(() => {
    if (typeof search === 'string') {
      if (search.length > 3) {
        setTableLoading(true);
        getSubscriberList(100, 1, search).then((res) => {
          setSubscribers(res.data);
        });
      } else {
        fetchSubscribers();
      }
    }
  }, [search]);

  // update table rows on fetch
  useEffect(() => {
    if (subscribers) {
      setRows(subscribers);
      setTableLoading(false);
    }
  }, [subscribers]);

  const deleteSubscriber = async (id) => {
    deleteSubscriberById(id).then(() => {
      fetchSubscribers();
    });
  };

  //  table columns
  const columns = [
    {
      field: 'type',
      headerName: 'TYPE',
      editable: false,
      minWidth: 90,
      flex: 1,
    },
    {
      field: 'cName',
      headerName: 'COMPANY NAME',
      editable: false,
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'EMAIL',
      type: 'email',
      editable: false,
      minWidth: 190,
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'PHONE',
      type: 'phone',
      editable: false,
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'firstName',
      headerName: 'FIRSTNAME',
      editable: false,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: 'LASTNAME',
      editable: false,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'industry',
      headerName: 'INDUSTRY',
      editable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <Grid container>
          {params.value.split(',').map((val) => {
            return (
              <Grid
                sx={{
                  backgroundColor: '#ededef',
                  borderRadius: '10px',
                  padding: '1px 7px',
                  margin: '1px',
                }}
              >
                {val}
              </Grid>
            );
          })}
        </Grid>
      ),
    },
    {
      field: 'country',
      headerName: 'COUNTRY',
      editable: false,
      minWidth: 90,
      flex: 1,
    },
    {
      field: 'city',
      headerName: 'CITY',
      editable: false,
      minWidth: 90,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      type: 'singleSelect',
      valueOptions: ['Subscribed', 'Not Subscribed'],
      editable: false,
      minWidth: 130,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.value === 'Subscribed' ? (
            <StatusChip
              text={params.value}
              color={'#8467E9'}
              bgColor={'#EAE5FB'}
              rounded={true}
            />
          ) : (
            <StatusChip
              text={params.value}
              color={'#DF3E70'}
              bgColor={'#F9E0E7'}
              rounded={true}
            />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      editable: false,
      minWidth: 90,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({
              open: true,
              type: 'delete-modal',
              id: params.id,
            });
          }}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      {openModal.type === 'import-subscribers' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'UPLOAD'}
          open={openModal.open}
          fetchData={fetchSubscribers}
          templateCSV={'/subscribers/export'}
          uploadLink={'/subscribers/import'}
        />
      )}
      {openModal.type === 'delete-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
          handleDelete={deleteSubscriber}
          id={openModal.id}
        />
      )}

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{ marginTop: 5, marginBottom: 1, fontSize: 28, color: '#3A3A50' }}
        >
          Subscribers
        </Typography>
      </Grid>
      <Grid
        sx={{
          width: '100%',
        }}
      >
        <Grid
          xs={12}
          sm={12}
          container
          justifyContent="flex-end"
          sx={{
            width: '100%',
            gap: '20px',
          }}
        >
          <CSVLink
            style={{ textDecoration: 'none' }}
            filename={'subscribers-export.csv'}
            headers={subscribersHeaders}
            data={subscribers}
          >
            <CustomButtonPages
              isIcon={true}
              icon={<UploadIcon />}
              onClick={() => {}}
              tooltipTitle="EXPORT TO CSV"
            />
          </CSVLink>
          <CustomButtonPages
            isIcon={true}
            icon={<DownloadIcon />}
            tooltipTitle="Import"
            onClick={() =>
              setOpenModal({ open: true, type: 'import-subscribers' })
            }
          />
        </Grid>
      </Grid>
      {/* --------------------SEARCH------------------------------- */}
      <Grid
        item
        xs={12}
        sx={{
          marginTop: { xs: '15px' },
          paddingBottom: { xs: '30px' },
          borderBottom: { xs: '1px solid #e3eaf3' },
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            height: '60px',
            borderRadius: '2px',
            boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '60px',
            }}
          >
            <SearchIcon sx={{ color: '#b0c1c9', fontSize: '28px' }} />
          </div>
          <input
            style={{
              height: '100%',
              width: '100%',
              fontSize: '14px',
              boxSizing: 'border-box',
              border: 'none',
              outline: 'none',
              color: '#292d32',
            }}
            type="text"
            placeholder="Quick search"
            onChange={(e) => {
              debouncedOnChangeHandler(e);
            }}
          />
        </div>
      </Grid>
      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
                color: '#3A3A50',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell': {
                color: '#3A3A50',
              },
              minHeight: '60vh',
            }}
            rows={rows}
            columns={columns}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            loading={tableLoading}
            autoHeight
            getRowHeight={() => 'auto'}
            components={{
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Subscribers;
