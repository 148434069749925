import { Button } from '@mui/material';
import React from 'react';
import CustomTooltip from '../CustomTooltip';

const CustomButtonPages = ({
  title,
  isIcon = false,
  icon,
  onClick,
  tooltipTitle,
  buttonProps,
  blue = false,
  del = false,
}) => {
  return isIcon ? (
    <CustomTooltip arrow placement="top" title={tooltipTitle}>
      <Button
        size="large"
        sx={{
          width: { xs: '100%', sm: 'auto' },
          height: isIcon ? '40px' : 'auto',
          '&.MuiButtonBase-root.MuiButton-root': {
            height: '40px !important',
            minWidth: '35px !important',
          },
          marginTop: 1,
          marginBottom: 1,
          color: blue === true ? '#fff' : del ? '#d32f2f' : '#1976d2',
          backgroundColor: blue === true ? '#1976d2' : del ? '#d32f2f' : '#fff',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
          textTransform: 'capitalize',
        }}
        onClick={onClick}
      >
        {isIcon ? icon : title}
      </Button>
    </CustomTooltip>
  ) : (
    <Button
      {...buttonProps}
      size="large"
      sx={{
        width: { xs: '100%', sm: 'auto' },
        height: isIcon ? '40px' : 'auto',
        marginTop: 1,
        marginBottom: 1,
        color: blue === true || del === true ? '#fff' : '#1976d2',
        backgroundColor: blue === true ? '#1976d2' : del ? '#d32f2f' : '#fff',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
        textTransform: 'capitalize',
        ':hover': { backgroundColor: blue ? '#1976d2' : del ? '#d32f2f' : '' },
      }}
      onClick={onClick}
    >
      {isIcon ? icon : title}
    </Button>
  );
};

export default CustomButtonPages;
