import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import error404Icon from "../../assets/images/error-404.png";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{ height: "80vh" }}
    >
      <img
        style={{ height: "200px", width: "200px" }}
        src={error404Icon}
        alt="error-404-icon"
      />
      <Grid sx={{ textAlign: "center", width: "460px" }}>
        <Typography fontSize="20px" sx={{ marginTop: 7 }}>
          The page you were looking for could not be found. It might have been
          removed, renamed, or did not exist in the first place.
        </Typography>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        sx={{ marginTop: 1, width: "230px" }}
      >
        <Button
          onClick={() => navigate(-1)}
          sx={{
            textDecoration: "underline",
            textTransform: "Capitalize",
            fontSize: "18px",
          }}
        >
          Go back
        </Button>
        <Button
          onClick={() => navigate("/")}
          sx={{
            textDecoration: "underline",
            textTransform: "Capitalize",
            fontSize: "18px",
          }}
        >
          Dashboard
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFound;
