import { CircularProgress } from "@mui/material";
import React from "react";
class CustomProgress extends React.Component {
  render() {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#4574cd",
          display: "flex",
          marginTop: "2%",
          borderRadius: "5px",
        }}
      >
        <CircularProgress
          sx={{ color: "white", marginTop: "0.5%", marginBottom: "0.5%" }}
        />
      </div>
    );
  }
}

export default CustomProgress;
